import styled from "styled-components"
import palette from "../styled-palette"

export const Wrapper = styled.div`
  padding: 50px 8% 0 8%;
  max-width: 1920px;
  font-size: 10px;
  margin: auto;

  @media (max-width: 1632px) {
    font-size: 8.5px;
  }
`

export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20em;

  img {
    max-width: 36.5em;
    width: 100%;
    object-fit: scale-down;
    margin-bottom: 2.4em;
  }

  h1 {
    font-size: 3em;
    font-family: ${palette.fontSofiaBold};
    line-height: 1.23em;
    color: ${palette.letterColor};
    text-align: center;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 4.9em;
    font-family: ${palette.fontCooperMedium};
    line-height: 1.53em;
    color: black;
    max-width: 893px;
    text-align: center;
    margin-bottom: 55px;
  }

  .list-article {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10em;

    @media (max-width: 1440px) {
      grid-gap: 5em;
    }

    @media (max-width: 1366px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 4em;
    }

    @media (max-width: 768px) {
      grid-gap: 10em;
      grid-template-columns: 1fr;
    }

    article {
      display: flex;
      flex-direction: column;
      border-radius: 4em;
      box-shadow: 0px 3px 10px #00262333;
      max-width: 61.3em;
      width: 100%;
      padding: 5em 3.8em;
      height: 100%;

      @media (max-width: 545px) {
        padding: 5em 2em;
      }

      h3 {
        font-size: 3.8em;
        line-height: 1.24em;
        font-family: ${palette.fontSofiaProBLack};
        color: ${palette.colorBlack};
        margin-bottom: 20px;
      }

      h4 {
        font-size: 3.4em;
        line-height: 1.24em;
        font-family: ${palette.fontSofiaProBLack};
        color: ${palette.letterColor};
        margin-bottom: 8px;
      }

      .article-description {
        font-size: 2.2em;
        font-family: ${palette.fontSofia};
        color: ${palette.colorBlack};
        margin-bottom: 20px;
        min-height: 4em;

        span {
          font-family: ${palette.fontSofiaBold};
        }
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 3.2em;

        li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 2.3em;
          line-height: 1em;
          font-family: ${palette.fontSofia};
          color: ${palette.colorBlack};

          @media (max-width: 545px) {
            line-height: 1.5em;
          }

          svg {
            flex-shrink: 0;
          }

          span {
            display: flex;
            align-items: center;
            gap: 1em;
          }

          > div {
            margin-bottom: 1.6em;
            margin-top: 2em;
            display: flex;
            flex-direction: column;
            margin-left: 4.2em;
            gap: 0.8em;

            @media (max-width: 545px) {
              margin-left: 2em;
            }
          }
        }
      }

      .article-message {
        font-size: 2.3em;
        line-height: 1.22em;
        font-family: ${palette.fontSofiaBold};
        margin-top: 50px;
        text-align: center;
        margin-bottom: 28px;
      }

      a, button {
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 2.5em;
        height: 51px;
        border-radius: 57px;
        background-color: ${palette.colorPurple};
        color: white;
        font-family: ${palette.fontSofia};
        text-decoration: none;
      }

      .article-acction {
        margin-top: auto;
      }
    }
  }
`
export const TestimonialsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16em;

  h2 {
    font-size: 4em;
    line-height: 1.525em;
    font-family: ${palette.fontCooperMedium};
    max-width: 800px;
    width: 100%;
    color: black;
    text-align: center;
    margin-bottom: 52px;
  }

  .testimonials-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5em;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10em;
      align-items: center;
    }

    article {
      display: grid;
      max-width: 387px;
      width: 100%;
      grid-template-rows: repeat(5, min-content);

      @media (max-width: 768px) {
        justify-items: center;
      }

      img {
        width: 20em;
        height: 20em;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        margin-bottom: 3.2em;
      }

      .star-list {
        display: flex;
        gap: 1em;
        margin-bottom: 2em;
      }

      h5 {
        font-size: 2.4em;
        line-height: 1.21em;
        font-family: ${palette.fontSofiaBold};
        color: black;
        min-height: 1.8em;
      }

      h6 {
        font-size: 2em;
        line-height: 1.5em;
        font-family: ${palette.fontSofia};
        color: black;
        margin: 0;
        margin-bottom: 20px;
        min-height: 3em;

        @media (max-width: 860px) {
          min-height: 5em;
        }

        @media (max-width: 768px) {
          min-height: auto;
        }
      }

      p {
        font-size: 2em;
        line-height: 1.5em;
        font-family: ${palette.fontSofia};
        width: 100%;
        color: black;
        margin: 0;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }
`
