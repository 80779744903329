import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import academyLogo from "../images/logo-academy-main.png"
import axios from "axios"
import CryptoJS from "crypto-js"
import { HeroSection, TestimonialsSection, Wrapper } from "../styles/pages/main"
import { APERTURE_ELASTIC_URL } from "../system/environment"

export default function Home() {
  const copies = {
    program: {
      title: "Programa de empleabilidad",
      subtitle: "1 mes de sueldo al éxito",
      description:
        "<span>Diferido a 3 meses</span> y solo pagas cuando consigas empleo",
      list: [
        {
          icon: <StartIcon />,
          text: "Sin costo inicial",
        },
        {
          icon: <StartIcon />,
          text: "Acompañamiento hasta conseguir empleo",
        },
        {
          icon: <StartIcon />,
          text: "Sesiones en videollamada",
        },
        {
          icon: <StartIcon />,
          text: "Seguimiento vía WhatsApp",
        },
        {
          icon: <StartIcon />,
          text: "Mejora de CV y portales de empleo",
        },
        {
          icon: <StartIcon />,
          text: "Simulación de entrevista",
        },
        {
          icon: <StartIcon />,
          text: "Cursos de capacitación",
        },
        {
          icon: <StartIcon />,
          text: "Capacitaciones en vivo",
        },
        {
          icon: <StartIcon />,
          text: "Acceso a tablero de vacantes",
        },
        {
          icon: <StartIcon />,
          text: "Acceso a la comunidad de piezeros",
        },
      ],
      message: "🇲🇽 Solo disponible en México",
      button: {
        text: "Más información",
      },
    },
    sessions: {
      title: "Sesiones individuales",
      subtitle: "$700 MXN",
      description: "<span>Precio por cada asesoría equivale a 38.88USD</span>",
      list: [
        {
          icon: <StartIcon />,
          text: "Ideal para resolver problemas puntuales",
        },
        {
          icon: <StartIcon />,
          text: "Acompañamiento durante la sesión",
        },
        {
          icon: <StartIcon />,
          text: "Sesión personalizada:",
          list: [
            "- Mejora de CV",
            "- Mejora de LinkedIn",
            "- Preparación entrevista",
          ],
        },
        {
          icon: <StartIcon />,
          text: "Retroalimentación de la sesión",
        },
        {
          icon: <StartIcon />,
          text: "Grabación de la sesión",
        },
      ],
      message: "Disponible para todo Latinoamérica",
      button: {
        text: "Agendar sesión",
      },
    },
  }

  const testimonial = [
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2Fbrenda.png?alt=media&token=1761dbe5-0b5d-4a53-95b1-096ad875e993",
      description:
        "El apoyo con la optimización de linkedin es tan bueno que incluso ahora que no me encuentro buscando trabajo, de vez en cuando me buscan para proponerme ofertas laborales.",
      names: "Brenda Arteche",
      position: "Diseñador Gráfico - AP Diseño",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2Frene.png?alt=media&token=89182069-6515-4210-a63d-83ed60003545",
      description:
        "Excelente apoyo durante mi búsqueda de empleo, ayuda en gran medida a saber cómo abordar las entrevistas, además de mejorar plataformas y cv.",
      names: "René Yepiz",
      position: "Desarrollador de Front-End  - Weird Fishes Studio",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2Fhector.png?alt=media&token=0b94c647-720c-4953-bb36-d2b7a6dbf482",
      description:
        "Pasé más de un año buscando empleo por mi cuenta sin obtener respuestas, al utilizar este servicio no solamente pude entender algunas cosas que estaba realizando de manera incorrecta, sino, que me fueron proporcionadas bastantes herramientas para buscar empleo eficazmente.",
      names: "Héctor Soto",
      position: "Técnico Inspector - Nacional Monte de Piedad",
    },
  ]

  function encryptREST(text) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(text),
      // eslint-disable-next-line no-undef
      process.env.GATSBY_PUBLIC_SECRET_KEY_APERTURE_ELASTIC
    ).toString()
  }

  async function saveVisitiToDB(params = {}) {
    const { type, email, docID, action } = params
    try {
      const data = {
        query: encryptREST({
          type,
          email,
          docID,
          action,
        }),
      }
      const response = await axios.request({
        headers: {
          "Access-Control-Allow-Headers": "*",
          "Content-Type": "Application/JSON",
          securityencrypt: true,
        },
        url: `${APERTURE_ELASTIC_URL}/security/api/academy/visit-home`,
        method: "POST",
        data,
        crossDomain: true,
      })
      return response?.data?.docID || ""
    } catch (error) {
      return null
    }
  }

  async function goToLink(url, target = "", action = "") {
    const visitDocID = localStorage.getItem("visitDocID")
    if (visitDocID) {
      const params = {
        type: "update",
        email: null,
        docID: visitDocID,
        action,
      }

      const docID = await saveVisitiToDB(params)
      if (docID) {
        localStorage.removeItem("visitDocID")
      }
    }

    if (target === "_blank") {
      window.open(url, "_blank", "noopener,noreferrer")
    } else {
      window.location.href = url
    }
  }

  React.useEffect(() => {
    const isBrowser = () => typeof window !== "undefined"
    if (isBrowser) {
      const urlParams = new URLSearchParams(window.location.search)
      const email = urlParams.get("email")

      if (email) {
        saveVisitiToDB({
          type: "visit",
          email,
          docID: null,
          action: "visit",
        }).then(visitDocID => {
          localStorage.setItem("visitDocID", visitDocID)
          const newUrl = window.location.href.split("?")[0]
          window.history.replaceState({}, document.title, newUrl)
        })
      }
    }
  }, [])

  return (
    <Layout withoutHeader>
      <Seo
        title="Home"
        description={`¡Descubre nuestro Programa de Empleabilidad para México! ¡Transformamos tu búsqueda de empleo desde el primer instante!`}
      />
      <Wrapper>
        <HeroSection>
          <img src={academyLogo} alt="" />
          <h1>¡Te ayudamos a encontrar tu empleo ideal!</h1>
          <h2>Elige el servicio que más se acomple a tus necesidades</h2>
          <div className="list-article">
            <article>
              <h3>{copies.program.title}</h3>
              <h4>{copies.program.subtitle}</h4>
              <p
                className="article-description"
                dangerouslySetInnerHTML={{
                  __html: copies.program.description,
                }}
              />
              <ul>
                {copies.program.list.map((item, idx) => (
                  <li key={idx}>
                    <span>
                      {item.icon} {item.text}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="article-acction">
                <p className="article-message">{copies.program.message}</p>
                <button
                  type="button"
                  onClick={() =>
                    goToLink(
                      "https://academy.lapieza.io/programa-de-empleabilidad",
                      null,
                      "programa-de-empleabilidad"
                    )
                  }
                >
                  {copies.program.button.text}
                </button>
              </div>
            </article>
            <article>
              <h3>{copies.sessions.title}</h3>
              <h4>{copies.sessions.subtitle}</h4>
              <p
                className="article-description"
                dangerouslySetInnerHTML={{
                  __html: copies.sessions.description,
                }}
              />
              <ul>
                {copies.sessions.list.map((item, idx) => (
                  <li key={idx}>
                    <span>
                      {item.icon} {item.text}
                    </span>
                    {item?.list?.length > 0 && (
                      <div>
                        {item?.list?.map((text, idx) => (
                          <span key={idx}>{text}</span>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              <div className="article-acction">
                <p className="article-message">{copies.sessions.message}</p>
                <button
                  type="button"
                  onClick={() =>
                    goToLink(
                      "https://bit.ly/3S6z5n2",
                      "_blank",
                      "sesiones-individuales"
                    )
                  }
                >
                  {copies.sessions.button.text}
                </button>
              </div>
            </article>
          </div>
        </HeroSection>
        <TestimonialsSection>
          <h2>Hemos ayudado a +250 personas a conseguir empleo</h2>
          <div className="testimonials-list">
            {testimonial.slice(0, 3).map((testimonial, idx) => (
              <article key={idx}>
                <img src={testimonial.img} alt="" />
                <div className="star-list">
                  <StartIcon />
                  <StartIcon />
                  <StartIcon />
                  <StartIcon />
                  <StartIcon />
                </div>
                <h5>{testimonial.names}</h5>
                <h6>{testimonial.position}</h6>
                <p>{testimonial.description}</p>
              </article>
            ))}
          </div>
        </TestimonialsSection>
      </Wrapper>
    </Layout>
  )
}

const StartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.538"
      height="23.499"
      viewBox="0 0 24.538 23.499"
    >
      <path
        id="Path_11475"
        data-name="Path 11475"
        d="M10.783,1.273a1.657,1.657,0,0,1,2.971,0l2.893,5.863,6.47.94a1.657,1.657,0,0,1,.918,2.826l-4.682,4.563,1.105,6.444a1.657,1.657,0,0,1-2.4,1.746l-5.787-3.042L6.482,23.655a1.657,1.657,0,0,1-2.4-1.746l1.105-6.444L.5,10.9A1.657,1.657,0,0,1,1.42,8.076l6.47-.94Z"
        transform="translate(0 -0.35)"
        fill="#ffc728"
      />
    </svg>
  )
}
